import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./ProductCard.css";
import Heart_Component from "../Heart_Component/Heart_Component";
import Button_Common from "../Button_Common/Button_Common";
import { Rating } from "@mui/material";
import { faCartPlus, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Bottom_Successfull_Modal from "../Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import { Context } from "../../../utils/context";
import Compare_Component from "../Compare_Component/Compare_Component";

const ProductCard = ({
  id,
  image,
  title,
  size,
  price,
  mrp,
  short_description,
  discount_percentage,
  average_rating,
  save,
}) => {
  const [rating, setRating] = useState(0);
  const [cardshow, setCardshow] = useState(false);
  const navigate = useNavigate();

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleClick = () => {
    // setCardshow(true);
    // setTimeout(() => {
    //   setCardshow(false);
    // }, 1000);

    navigate(`/sample-roll/${id}`);
  };
  // Optinal callback functions
  const onPointerEnter = () => {
    // console.log("Enter");
  };
  const onPointerLeave = () => {
    // console.log("Leave");
  };
  const onPointerMove = (value, index) => {
    // console.log(value, index);
  };

  //   ----------------------------------------------------------------
  const { IMG_URL, wishlist, compare } = useContext(Context);

  return (
    <>
      <section className="ProductCard">
        {/* <Row>
                    <Col xxl={2}> */}
        <Card>
          <div className="Product-card">
            <div className="Main">
              <Link to={`/sample-roll/${id}`}>
                <div className="Image-Tape">
                  <img src={IMG_URL + image} className="tape" />
                </div>
              </Link>
              <div className="heart">
                <Heart_Component
                  status={wishlist?.some((item) => item.id === id)}
                  product_id={id}
                />

                <Compare_Component
                  status={compare?.some((item) => item.id === id)}
                  product_id={id}
                />
                {/* <Link to={"/comparelist"}> */}
                {/* <div className="compare mt-1">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assests/Products/compress.png"
                    }
                    className="tape"
                  />
                </div> */}
                {/* </Link> */}
              </div>
            </div>
            {/* <div className="triangle-element"></div> */}
            {/* <div className="sale-off">
                            <p className="off">
                                20% <br></br>off
                            </p>
                        </div> */}
          </div>
          <Card.Body>
            <p className="sub-text">{title}</p>
            <p className="sub-text">{short_description}</p>
            {/* <p className="sub-text">{size}</p> */}
            <div className="price">
              <div>
                <p className="sale-price">₹ {price}</p>
                <p className="main-price">
                  {" "}
                  ({(((mrp - price) / mrp) * 100).toFixed(0)}% off)
                </p>
              </div>

              <p className="main-price">
                M.R.P <span className="real-price">₹ {mrp} </span>
                <br />
                (Inclusive of all taxes)
              </p>
            </div>
            <p className=""></p>
            <div className="rating">
              <p className="sub-text">Ratings:</p>

              <Rating value={average_rating} readOnly />
              {/* <Rating
                onClick={handleRating}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                onPointerMove={onPointerMove}
              /> */}
            </div>

            <p className="saveprisefont">
              <span className="saveprise">Save {save}</span>{" "}
              <span> with coupon</span>
            </p>

            <div className="Cart-Btn mt-2">
              <div className="cart">
                <Button_Common
                  icon={faCartPlus}
                  text="Add to Cart"
                  className="cart sub-text"
                  onClick={handleClick}
                />
              </div>
              <div className="buy">
                <Button_Common
                  icon={faCartPlus}
                  text="Buy Now"
                  className="buy sub-text"
                  onClick={() => navigate(`/sample-roll/${id}`)}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
        {/* </Col>
                </Row> */}

        <Bottom_Successfull_Modal
          onClick={() => setCardshow(true)}
          show={cardshow}
          icon={faCircleCheck}
          text="Product added to the cart successfully!"
        />
      </section>
    </>
  );
};

export default ProductCard;
