import React, { useContext, useEffect, useState } from "react";
import "./CustomizeTape.css";
import Breadcrumbs from "../CommonComponent/Breadcrumbs/Breadcrumbs";
import { Breadcrumb, Col, Container, Form, Row } from "react-bootstrap";
import CustomerInformation from "./CustomerInformation";
import ProductInformation from "./ProductInformation";
import OrderDetail from "./OrderDetail";
import CustomerFeedback from "./CustomerFeedback";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../utils/context";
import Button_Common from "../CommonComponent/Button_Common/Button_Common";

const CustomizeTape = () => {
  const [current, setCurrent] = useState(1);
  const stepLabels = [
    "1. Customer Information",
    "2. Product Information",
    "3.Repeated Order Details",
  ];
  const stepLabelsBread = [
    "Address Details",
    "Payment Options",
    "Confirm Order",
  ];

  const handleNext = () => {
    if (current < 3) {
      setCurrent(current + 1);
    }
  };

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const isStepCompleted = (step) => {
    return step < current;
  };

  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Customized Tape Requirement Form", link: "", className: "active" },
  ]);
  const getBreadcrumbItems = () => {
    const items = [];

    for (let i = 1; i <= current; i++) {
      items.push(
        <Breadcrumb.Item key={i} active={i === current}>
          {stepLabelsBread[i - 1]}
        </Breadcrumb.Item>
      );
    }

    return items;
  };

  const { IMG_URL, htmlToReactParser, postData, Cookies } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    // console.log("data", data);
    // const res = await postData(data);
    // if (res?.success) {
    //     // setModalShow(true);
    // } else {
    //     console.error("Error fetching  data:", res?.error);
    // }
  };

  return (
    <>
      <Container>
        <Breadcrumbs items={breadcrumbItems} />
        <section className="Customize-Tape">
          <div className="Banner-Customize">
            <img
              src={process.env.PUBLIC_URL + "/assests/Customize/customize.png"}
              className="custome"
            />
            <img
              src={process.env.PUBLIC_URL + "/assests/Customize/customer.png"}
              className="custome2"
            />
            {/* checkout-process-step-form start  */}
            <div className="Main-customize">
              <Row className="justify-content-center">
                <Col xxl={8} xl={8} lg={8}>
                  <section className="checkout-process-step-form Product_Cart">
                    <div className="heading_holder">
                      {/* <Breadcrumb>{getBreadcrumbItems()}</Breadcrumb> */}
                    </div>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <div className="progress-bar-container">
                        <div className="step-row">
                          {stepLabels.map((icon, index) => (
                            <div
                              key={index + 1}
                              className={`step-container ${
                                index + 1 === current ? "active" : ""
                              }`}
                            >
                              <div>
                                <span className="step-text small-text">
                                  {stepLabels[index]}
                                </span>
                              </div>
                              <div className="circle-center">
                                <div
                                  className={`circle ${
                                    isStepCompleted(index + 1)
                                      ? "completed"
                                      : ""
                                  }`}
                                ></div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="steps">
                        {current === 1 && (
                          <CustomerInformation
                            handleNext={handleNext}
                            setValue={setValue}
                            register={register}
                            errors={errors}
                            classNames={classNames}
                            getValues={getValues}
                            handleSubmit={handleSubmit}
                            Cookies={Cookies}
                          />
                        )}
                        {current === 2 && (
                          <ProductInformation
                            handleNext={handleNext}
                            handlePrevious={handlePrevious}
                            register={register}
                            errors={errors}
                            classNames={classNames}
                            Cookies={Cookies}
                          />
                        )}
                        {current === 3 && (
                          <div className="order-detail-section">
                            <OrderDetail
                              handlePrevious={handlePrevious}
                              handleSubmit={handleSubmit(onSubmit)}
                              Cookies={Cookies}
                            />
                          </div>
                        )}
                      </div>
                      {/* <div className="apply_now_btn">
                                                <Button_Common onClick={handlePrevious} className="back-btn me-3 small-text back" text="BACK">
                                                    Back
                                                </Button_Common>

                                                <Button_Common
                                                    type="button"
                                                    onClick={handleNext}
                                                    className="tabs-btn small-text orange"
                                                    text="SAVE & NEXT"
                                                >
                                                    Next
                                                </Button_Common>
                                            </div> */}
                    </Form>
                  </section>
                  {/* {current === 3 && (
                    <div className="customer-information-section">
                      <CustomerFeedback getValues={getValues} />
                    </div>
                  )} */}
                </Col>
              </Row>
            </div>

            {/* checkout-process-step-form end  */}
          </div>
        </section>
      </Container>
    </>
  );
};

export default CustomizeTape;
