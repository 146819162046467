import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import "./SearchBar.css";
import { Container, ListGroup } from "react-bootstrap";
const SearchBar = ({
  name,
  search,
  products,
  setSearch,
  handleNavigate,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="Search-Bar">
        <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleShow} />

        <Offcanvas
          className="searchoffcanva"
          show={show}
          onHide={handleClose}
          {...props}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Container>
              <div className="search-main">
                <Form className="search">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      onChange={(e) => setSearch(e?.target?.value)}
                      placeholder="Search Here.."
                    />
                  </Form.Group>
                </Form>

                {search && (
                  <ListGroup>
                    {products?.length > 0 ? (
                      <>
                        {products?.map((product) => (
                          <ListGroup.Item
                            key={product?.value}
                            onClick={() => {
                              handleNavigate(product?.value);
                              handleClose();
                            }}
                          >
                            {product?.label}
                          </ListGroup.Item>
                        ))}
                      </>
                    ) : (
                      <p>No Product Found By this Search</p>
                    )}
                  </ListGroup>
                )}
                <div className="icon">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="icon-search"
                  />
                </div>
              </div>
            </Container>
          </Offcanvas.Body>
        </Offcanvas>
      </section>
    </>
  );
};

export default SearchBar;
