import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Register = async (data) => {
  try {
    const res = await postData(`/auth/register`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const Login = async (data) => {
  try {
    const res = await postData(`/auth/login`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const MobileLogin = async (data) => {
  try {
    const res = await postData(`/auth/mobile-login`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ChangePassword = async (data) => {
  try {
    const res = await postData(`/auth/change-password`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const GetProfile = async () => {
  try {
    const res = await getData(`/auth/profile`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ProfileEdit = async (data) => {
  try {
    const res = await postData(`/auth/profile`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ForgetPassword = async (data) => {
  try {
    const res = await postData(`/auth/forget-password`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
